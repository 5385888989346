import React, {useState} from 'react';
import './App.css';

import Bingo from './Bingo';
import Intro from './Intro';
import Photobooth from './Photobooth';
import Zodiac from './Zodiac';

function getPage(pageName: string, setPage: (page: string) => void) {
  switch (pageName) {
    case 'INTRO':
      return (
        <Intro onClick={() => setPage('ZODIAC')} onClickAlternate={() => setPage('PHOTOBOOTH')} />
      );
    case 'PHOTOBOOTH':
      return (
        <Photobooth onComplete={() => setPage('INTRO')} />
      );
    case 'BINGO':
      return <Bingo />;
    case 'ZODIAC':
      return <Zodiac />;
  }
}

function App() {
  const [page, setPage] = useState<string>('INTRO');
  return (
    <div className="App">
      {getPage(page, setPage)}
    </div>
  );
}

export default App;
