import {useState, useEffect} from 'react';

import shuffle from 'shuffle-array';

import './Bingo.css';

interface BingoTile {
  title: string;
  description: string;
  validMatch?: string[];
  image?: string;
  enableNote?: boolean;
}

const BINGO_GRID_SIZE = 4;

const bingoState: number[][] = [...Array(BINGO_GRID_SIZE)].map((_, idx) => (
  [...Array(BINGO_GRID_SIZE)].map((_, idx) => 0)
));

const TILES: BingoTile[] = [
  {
    title: 'Ice Q Mini tasting',
    description: 'Eat an ice q mini',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Golden Mini tasting',
    description: 'Eat a golden mini',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Hong Kong Mini tasting',
    description: 'Eat a Hong Kong mini',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Fruit Mini tasting',
    description: 'Eat a fruit mini',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Sample Puer tea',
    description: 'Sample the Puer tea',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Sample hisbiscus spice tea',
    description: 'Taste the hibiscus spice',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Sample the Jasmine tea',
    description: 'Taste the jasmine tea leaves',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Sample the Ten Wu tea',
    description: 'Taste the ten wu fine oolong',
    image: '/images/mooncake-logo.png',
  },
  {
    title: 'Hear the tale of Chang\'e',
    description: 'How many times per year can Chang\'e and Houyi see each other?',
    enableNote: true,
    validMatch: ['1', 'one'],
  },
  {
    title: 'Learn about Chang\'e\'s companion on the moon',
    description: 'What is the name of her companion?',
    enableNote: true,
    validMatch: ['rabbit'],
  },
  {
    title: 'Learn about the Autumn Moon Festival',
    description: 'What celestial event corresponds to the festival?',
    enableNote: true,
    validMatch: ['full moon'],
  },
  {
    title: 'Learn about the history',
    description: 'Roughly how many years ago did the moon festival start?',
    enableNote: true,
    validMatch: ['3000'],
  },
  {
    title: 'Hear the tale of Chang\'e',
    description: 'Which celestial objects did Houyi shoot down?',
    enableNote: true,
    validMatch: ['sun'],
  },
  {
    title: 'Learn about the history',
    description : 'How were mooncakes used in the uprising aganist the Mongols?',
    enableNote: true,
    validMatch: ['conceal message', 'hide message', 'conceal', 'hide', 'message', 'signal'],
  },
  {
    title: 'Learn about the science',
    description : 'Who took the first step on the moon (other than Chang\'e)?',
    enableNote: true,
    validMatch: ['neil armstrong', 'buzz aldrin', 'neil', 'armstrong', 'buzz', 'aldrin'],
  },
  {
    title: 'Learn about the history',
    description : 'What do festival laterns symbolize? [1 or 2 words]',
    enableNote: true,
    validMatch: ['reunion', 'family reunion'],
  },
];

const randomTiles = shuffle(TILES, { copy: true });
let nextTileIndex = 0;

function BingoCell({
  col,
  onChange,
}: {
  col: number,
  onChange: (col: number, selected: boolean) => void,
}) {
  const [selected, setSelected] = useState<boolean>(false);
  const [tile, setTile] = useState<BingoTile | null>(null);
  const [note, setNote] = useState<string>('');
  useEffect(() => {
    setTile(randomTiles[nextTileIndex++]);
  }, []);

  function handleClick() {
    const selectedState = selected;
    if (tile && tile.enableNote && !selectedState) {
      const answer = prompt(`${tile.title}:\n${tile.description}`);
      if (answer) {
        if (tile.validMatch) {
          const lowerAnswer = answer.toLowerCase();
          const isValid = tile.validMatch.some(match => lowerAnswer.indexOf(match) > -1);
          if (!isValid) {
            alert('Sorry, answer is rejected! Please try again.');
            return;
          }
        }
        setNote(answer);
        setSelected(!selectedState);
        onChange(col, !selectedState);
      }
    } else {
      setSelected(!selectedState);
      onChange(col, !selectedState);
    }
  }

  const selectedClass = selected ? 'selected' : '';
  if (!tile) {
    return (
      <div className={`bingo-cell ${selectedClass}`} onClick={handleClick}>
        <h3>Loading...</h3>
      </div>
    );
  }
  return (
    <div className={`bingo-cell ${selectedClass}`} onClick={handleClick}>
      <h3>{tile.title}</h3>
      <p>{tile.description}</p>
      {tile.image ? (
        <img src={tile.image} alt={tile.title} />
      ) : null}
      {tile.enableNote ? (
        <em>
          ✏️ {note ? note : 'Click to answer...'}
        </em>
      ) : null}
    </div>
  );
}

function BingoRow({
  row,
  onChange,
}: {
  row: number,
  onChange: (row: number, col: number, selected: boolean) => void,
}) {
  const cols = [...Array(BINGO_GRID_SIZE)].map((_, idx) => (
    <BingoCell key={idx} col={idx} onChange={onChange.bind(null, row)} />
  ));
  return (
    <div className="bingo-row">
      {cols}
    </div>
  );
}

export default function Bingo() {
  const [boardComplete, setBoardComplete] = useState<boolean>(false);

  useEffect(() => {
    window.onbeforeunload = function(e) {
      const didAnything = bingoState.some(row => row.some(cell => cell === 1));
      if (didAnything && !window.confirm('Your game is not saved. Do you really want to leave?')) {
        e.preventDefault();
        e.returnValue = '';
      }
    }
  }, []);

  function onChange(row: number, col: number, selected: boolean) {
    // Transform to be row-oriented
    bingoState[col][row] = selected ? 1 : 0;
    console.log('==== BINGO BOARD ====');
    bingoState.forEach(row => {
      console.log(row.map(cell => cell ? 1 : 0));
    });

    let rightDiagonalWin = true;
    let leftDiagonalWin = true;
    let rowWin = false;
    let colWin = false;
    for (let i=0; i < BINGO_GRID_SIZE; i++) {
      // Diag
      if (bingoState[i][i] !== 1) {
        rightDiagonalWin = rightDiagonalWin && false;
      }
      if (bingoState[BINGO_GRID_SIZE - i - 1][i] !== 1) {
        leftDiagonalWin = leftDiagonalWin && false;
      }

      // Row
      let thisRow = true;
      let thisCol = true;
      for (let j=0; j < BINGO_GRID_SIZE; j++) {
        if (bingoState[i][j] !== 1) {
          thisRow = false;
        }
        if (bingoState[j][i] !== 1) {
          thisCol = false;
        }
      }
      if (thisRow) {
        rowWin = true;
        break;
      }
      if (thisCol) {
        colWin = true;
        break;
      }
    }
    console.log('win conditions', {rowWin, colWin, leftDiagonalWin, rightDiagonalWin});

    setBoardComplete(rowWin || colWin || leftDiagonalWin || rightDiagonalWin);
  }

  const rows = [...Array(BINGO_GRID_SIZE)].map((_, idx) => (
    <BingoRow key={idx} row={idx} onChange={onChange} />
  ));
  return (
    <div className="bingo-container">
      <div className="bingo-board">
        {rows}
      </div>
      {boardComplete ? (
        <div className="win-banner">
          Congratulations, your bingo board is complete! Let the team know.
        </div>
      ) : null}
    </div>
  );
}
