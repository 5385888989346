export default function Intro({
  onClick,
  onClickAlternate,
}: {
  onClick: () => void;
  onClickAlternate: () => void;
}) {
  return (
    <header className="App-header">
      <img src="/images/tiger.jpg" className="App-logo" alt="logo" />
      <p>
        Lunar New Year activity
      </p>
      <button
        className="cta"
        onClick={() => onClick()}
      >
        Get Started
      </button>
      <div className="upload-link-container">
        or <a className="upload-link" href="#photo" onClick={() => onClickAlternate()}>upload team photo</a>
      </div>
    </header>
  );
}
