// https://www.timeanddate.com/calendar/chinese-zodiac-signs.html

const TABLE = [
{startDate: '1948-02-10', endDate: '1949-01-28', sign: 'rat'},
{startDate: '1949-01-29', endDate: '1950-02-16', sign: 'bull'},
{startDate: '1950-02-17', endDate: '1951-02-05', sign: 'tiger'},
{startDate: '1951-02-06', endDate: '1952-01-26', sign: 'rabbit'},
{startDate: '1952-01-27', endDate: '1953-02-13', sign: 'dragon'},
{startDate: '1953-02-14', endDate: '1954-02-02', sign: 'snake'},
{startDate: '1954-02-03', endDate: '1955-01-23', sign: 'horse'},
{startDate: '1955-01-24', endDate: '1956-02-11', sign: 'sheep'},
{startDate: '1956-02-12', endDate: '1957-01-30', sign: 'monkey'},
{startDate: '1957-01-31', endDate: '1958-02-17', sign: 'rooster'},
{startDate: '1958-02-18', endDate: '1959-02-08', sign: 'dog'},
{startDate: '1959-02-08', endDate: '1960-01-27', sign: 'pig'},
{startDate: '1960-01-28', endDate: '1961-02-14', sign: 'rat'},
{startDate: '1961-02-15', endDate: '1962-02-04', sign: 'bull'},
{startDate: '1962-02-05', endDate: '1963-01-24', sign: 'tiger'},
{startDate: '1963-01-25', endDate: '1964-02-12', sign: 'rabbit'},
{startDate: '1964-02-13', endDate: '1965-02-01', sign: 'dragon'},
{startDate: '1965-02-02', endDate: '1966-01-20', sign: 'snake'},
{startDate: '1966-01-21', endDate: '1967-02-08', sign: 'horse'},
{startDate: '1967-02-09', endDate: '1968-01-29', sign: 'sheep'},
{startDate: '1968-01-30', endDate: '1969-02-16', sign: 'monkey'},
{startDate: '1969-02-17', endDate: '1970-02-05', sign: 'rooster'},
{startDate: '1970-02-06', endDate: '1971-01-26', sign: 'dog'},
{startDate: '1971-01-27', endDate: '1972-02-14', sign: 'pig'},
{startDate: '1972-02-15', endDate: '1973-02-02', sign: 'rat'},
{startDate: '1973-02-03', endDate: '1974-01-22', sign: 'bull'},
{startDate: '1974-01-23', endDate: '1975-02-10', sign: 'tiger'},
{startDate: '1975-02-11', endDate: '1976-01-30', sign: 'rabbit'},
{startDate: '1976-01-31', endDate: '1977-02-17', sign: 'dragon'},
{startDate: '1977-02-18', endDate: '1978-02-06', sign: 'snake'},
{startDate: '1978-02-07', endDate: '1979-01-27', sign: 'horse'},
{startDate: '1979-01-28', endDate: '1980-02-15', sign: 'sheep'},
{startDate: '1980-02-16', endDate: '1981-02-04', sign: 'monkey'},
{startDate: '1981-02-05', endDate: '1982-01-24', sign: 'rooster'},
{startDate: '1982-01-25', endDate: '1983-02-12', sign: 'dog'},
{startDate: '1983-02-13', endDate: '1984-02-01', sign: 'pig'},
{startDate: '1984-02-02', endDate: '1985-02-19', sign: 'rat'},
{startDate: '1985-02-20', endDate: '1986-02-08', sign: 'bull'},
{startDate: '1986-02-09', endDate: '1987-01-28', sign: 'tiger'},
{startDate: '1987-01-29', endDate: '1988-02-16', sign: 'rabbit'},
{startDate: '1988-02-17', endDate: '1989-02-05', sign: 'dragon'},
{startDate: '1989-02-06', endDate: '1990-01-26', sign: 'snake'},
{startDate: '1990-01-27', endDate: '1991-02-14', sign: 'horse'},
{startDate: '1991-02-15', endDate: '1992-02-03', sign: 'sheep'},
{startDate: '1992-02-04', endDate: '1993-01-22', sign: 'monkey'},
{startDate: '1993-01-23', endDate: '1994-02-09', sign: 'rooster'},
{startDate: '1994-02-10', endDate: '1995-01-30', sign: 'dog'},
{startDate: '1995-01-31', endDate: '1996-02-18', sign: 'pig'},
{startDate: '1996-02-19', endDate: '1997-02-06', sign: 'rat'},
{startDate: '1997-02-07', endDate: '1998-01-27', sign: 'bull'},
{startDate: '1998-01-28', endDate: '1999-02-15', sign: 'tiger'},
{startDate: '1999-02-16', endDate: '2000-02-04', sign: 'rabbit'},
{startDate: '2000-02-05', endDate: '2001-01-23', sign: 'dragon'},
{startDate: '2001-01-24', endDate: '2002-02-11', sign: 'snake'},
{startDate: '2002-02-12', endDate: '2003-01-31', sign: 'horse'},
{startDate: '2003-02-01', endDate: '2004-01-21', sign: 'sheep'},
{startDate: '2004-01-22', endDate: '2005-02-08', sign: 'monkey'},
{startDate: '2005-02-09', endDate: '2006-01-28', sign: 'rooster'},
{startDate: '2006-01-29', endDate: '2007-02-17', sign: 'dog'},
{startDate: '2007-02-18', endDate: '2008-02-06', sign: 'pig'},
{startDate: '2008-02-07', endDate: '2009-01-25', sign: 'rat'},
{startDate: '2009-01-26', endDate: '2010-02-13', sign: 'bull'},
{startDate: '2010-02-14', endDate: '2011-02-02', sign: 'tiger'},
{startDate: '2011-02-03', endDate: '2012-01-22', sign: 'rabbit'},
{startDate: '2012-01-23', endDate: '2013-02-09', sign: 'dragon'},
{startDate: '2013-02-10', endDate: '2014-01-30', sign: 'snake'},
{startDate: '2014-01-31', endDate: '2015-02-18', sign: 'horse'},
{startDate: '2015-02-19', endDate: '2016-02-07', sign: 'sheep'},
{startDate: '2016-02-08', endDate: '2017-01-27', sign: 'monkey'},
{startDate: '2017-01-28', endDate: '2018-02-15', sign: 'rooster'},
{startDate: '2018-02-16', endDate: '2019-02-04', sign: 'dog'},
{startDate: '2019-02-05', endDate: '2020-01-24', sign: 'pig'},
{startDate: '2020-01-25', endDate: '2021-02-11', sign: 'rat'},
{startDate: '2021-02-12', endDate: '2022-01-31', sign: 'bull'},
{startDate: '2022-02-01', endDate: '2023-01-21', sign: 'tiger'},
{startDate: '2023-01-22', endDate: '2024-02-09', sign: 'rabbit'},
];

const TRAITS: Record<string, string> = {
  rat: 'quick-witted, smart, charming, and persuasive',
  bull: 'patient, kind, stubborn, and conservative',
  tiger: 'authoritative, emotional, courageous, and intense',
  rabbit: 'popular, compassionate, and sincere',
  dragon: 'energetic, fearless, warm-hearted, and charismatic',
  snake: 'charming, gregarious, introverted, generous, and smart',
  horse: 'energetic, independent, impatient, and enjoy traveling',
  sheep: 'mild-mannered, shy, kind, and peace-loving',
  monkey: 'fun, energetic, and active',
  rooster: 'independent, practical, hard-working, and observant',
  dog: 'patient, diligent, generous, faithful, and kind',
  pig: 'loving, tolerant, honest, and appreciative of luxury',
};

export function lookup(date: Date): string {
  let row;
  for (let i = 0; i < TABLE.length; i++) {
    row = TABLE[i];
    const endDate = new Date(row.endDate);
    if (endDate > date) {
      return row.sign;
    }
  }
  // Shouldn't happen
  return 'unknown';
}

export function getPersonalityTraits(sign: string): string {
  return TRAITS[sign];
}
