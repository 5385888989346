import { useState } from 'react';

import { lookup, getPersonalityTraits } from './zodiacLookup';

import './Zodiac.css';

export default function ZodiacLookup() {
  const [sign, setSign] = useState<string | null>(null);
  const [year, setYear] = useState<number>(1930);
  const [month, setMonth] = useState<number>(1);
  const [day, setDay] = useState<number>(1);

  const yearOptions = [];
  for (let year = 1930; year <= 2022; year++) {
    yearOptions.push(
      <option value={year} key={year}>{year}</option>
    );
  }

  const monthOptions = [1,2,3,4,5,6,7,8,9,10,11,12].map(month => <option value={month} key={month}>{month}</option>);

  const dayOptions = [];
  for (let day = 1; day <= 31; day++) {
    dayOptions.push(
      <option value={day} key={day}>{day}</option>
    );
  }

  function onClick() {
    const result = lookup(new Date(year, month - 1, day));
    if (!result) {
      alert("Something went wrong and we could not calculate your sign!");
    } else {
      setSign(result);
    }
  }

  function renderSign() {
    if (!sign) {
      return null;
    }
    return (
      <div className="result">
        <h2>You are a {sign[0].toUpperCase() + sign.slice(1)}!</h2>
        <img src={`/images/zodiac/chinese-zodiac-${sign}-new.png`} />
        <p>
          {getPersonalityTraits(sign)}
        </p>
      </div>
    );
  }

  return (
    <div className="main">
      <h1>When were you born?</h1>
      <div className="form">
        <div className="col">
          <label>Year <select onChange={e => setYear(Number(e.target.value))}>{yearOptions}</select></label>
        </div>
        <div className="col">
          <label>Month <select onChange={e => setMonth(Number(e.target.value))}>{monthOptions}</select></label>
        </div>
        <div className="col">
          <label>Day <select onChange={e => setDay(Number(e.target.value))}>{dayOptions}</select></label>
        </div>
      </div>
      <button
        className="cta"
        onClick={() => onClick()}
      >
        Lookup Zodiac sign
      </button>
      {renderSign()}
    </div>
  );
}
