import React, { useRef, useState, useEffect } from 'react';

import './Photobooth.css';

async function drawForeground(context: CanvasRenderingContext2D) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      context.drawImage(img,
        0, 0, img.width, img.height,
        0, 0, context.canvas.width, context.canvas.height
      );

      context.font = '48px Georgia';
      context.fillStyle = '#000';
      const msg = 'Lunar New Year 2022';
      const width = context.measureText(msg).width;
      const x = (context.canvas.width - width) / 2;
      const y = context.canvas.height - 30;
      context.fillText(msg, x, y);
      resolve(true);
    };
    img.src = '/images/photobooth-lunar-new-year-2.png';
  });
}

function drawBackground(context: CanvasRenderingContext2D) {
  context.fillStyle = '#f0ead6';
  context.fillRect(0, 0, context.canvas.width, context.canvas.height)
}

export default function Photobooth({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [uploaded, setUploaded] = useState<boolean>(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const context = canvas.getContext('2d')
    if (!context) {
      return;
    }
    drawBackground(context);
    drawForeground(context);
  }, []);

  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.onload = function(event) {
      const img = new Image();
      img.onload = async function(){
        const canvas = canvasRef.current;
        if (!canvas) {
          return;
        }
        const context = canvas.getContext('2d')
        if (!context) {
          return;
        }
        context.clearRect(0, 0, canvas.width, canvas.height);
        drawBackground(context);

        // canvas.width = img.width;
        // canvas.height = img.height;
        const newHeight = Math.min(img.height, canvas.height / 2);
        const newWidth = img.width * (newHeight / img.height);
        const x = (canvas.width - newWidth) / 2;
        const y = canvas.height - newHeight - 100;
        context.drawImage(img,
          x, y,
          newWidth, newHeight
        );

        await drawForeground(context);

        setUploaded(true);
      }
      if (event.target?.result && typeof event.target.result === 'string') {
        img.src = event.target.result;
      }
    }
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function handleDownload() {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const link = document.createElement('a');
    link.download = 'photo.png';
    link.href = canvas.toDataURL()
    link.click();
  }

  return (
    <div className="photobooth">
      <a className="breadcrumb" href="#intro" onClick={() => onComplete()}>&laquo; Return to home</a>
      <div className="prompt">
        <h1>Upload a file to fit in the picture frame:</h1>
        <input type="file" onChange={handleFileUpload} />
      </div>
      {uploaded ? (
        <div className="actions">
          <button className="cta" onClick={handleDownload}>Download picture frame</button>
        </div>
        ) : null}
      <canvas ref={canvasRef} width={canvasRef.current?.width ?? 945} height={canvasRef.current?.height ?? 945} />
    </div>
  );
}
